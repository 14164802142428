import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import TrueIntention from "../components/who-we-work-with/ourTrueIntention"
import Projects from "../components/who-we-work-with/projects"
import breadCrumbData from "../json/case-studio/breadCrumb.json"

const WhoWeWorkWith = () => (
  <Layout>
    <Breadcrumb
      description={breadCrumbData.description}
      title={breadCrumbData.title}
      image="who-we-work-with-breadcrumb-banner.png"
    />
    <TrueIntention />
    <Projects />
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default WhoWeWorkWith
